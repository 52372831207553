<script>
import { required } from "vuelidate/lib/validators";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import validationMessages from "@/components/validations";
import Monitor from "@/services/Monitor";
import Swal from "sweetalert2";

export default {
  components: { validationMessages},

  data() {
    return {
      points_reward: "",
      name: "",
      id: "",
      description: "",
      season: 0,
      showModal: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem("csrf_token"),
      error: null,
    };
  },

  validations: {
    id: { required },
    name: { required },
    points_reward: { required },
    description: { required },
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },

    async addSocialxp() {
      this.tryingToSubmit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }

      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("name", this.name);
      formData.append("points_reward", this.points_reward);
      formData.append("description", this.description);
      formData.append("season", this.season);
      formData.append("csrf_token", this.csrf_token);

      try {
        const response = await Monitor.createSocialXp(formData);
        if (response.error) {
          this.failedmsg(response.error);
        } else {
          this.successmsg("Social Xp successfully created");
          this.closeModal();
          this.refreshData();
        }
      } catch (error) {
        this.error = error.response?.data?.error || "An error occurred";
        this.failedmsg(this.error);
      } finally {
        this.tryingToSubmit = false;
      }
    },

    successmsg(message) {
      Swal.fire("Success", message, "success");
    },

    failedmsg(message) {
      Swal.fire("Error", message, "error");
    },

    refreshData() {
      this.$emit("onRefresh");
    },

    resetForm() {
      this.id = "";
      this.name = "";
      this.points_reward = "";
      this.description = "";
      this.season = "";
      this.submitted = false;
      this.tryingToSubmit = false;
      this.$v.$reset();
    },
  },
};
</script>

<template>
  <b-modal id="add_social_xp" size="l" v-model="showModal" title="Social XP" title-class="font-18">
    <form @submit.prevent="addSocialxp">
      <div class="row">
        <div v-if="submitted && $v.$error" class="text-danger">Complete all fields</div>
        <b-card-text>
          <div class="row">
            <div class="col-sm-12">
              <b-form-group label="ID" label-for="formrow-id-input" class="mb-3">
                <b-form-input v-model.trim="id" :class="{ 'is-invalid': submitted && $v.id.$error }"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Id'" :validationName="$v.id"></validationMessages>
              </b-form-group>

              <b-form-group label="Name" label-for="formrow-name-input" class="mb-3">
                <b-form-input v-model.trim="name" :class="{ 'is-invalid': submitted && $v.name.$error }"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Name'" :validationName="$v.name"></validationMessages>
              </b-form-group>

              <b-form-group label="Description" label-for="formrow-description-input" class="mb-3">
                <b-form-input v-model.trim="description" :class="{ 'is-invalid': submitted && $v.description.$error }"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Description'" :validationName="$v.description"></validationMessages>
              </b-form-group>

              <b-form-group label="Points Reward" label-for="formrow-points_reward-input" class="mb-3">
                <b-form-input v-model.trim="points_reward" :class="{ 'is-invalid': submitted && $v.points_reward.$error }"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Points Reward'" :validationName="$v.points_reward"></validationMessages>
              </b-form-group>

              <div class="col-sm-12 col-md-4">
                <h5>Season</h5>
                <b-form-checkbox-group >
                  <div class="form-check form-switch form-switch-lg mb-3" >
                    <input v-model="season"
                           type="checkbox"
                           class="form-check-input"
                           id="switch1"
                           true-value="1"
                           false-value="0"
                    />
                  </div>
                </b-form-checkbox-group>
              </div>
              <input type="hidden" name="csrf_token" v-model="csrf_token">
            </div>
          </div>
        </b-card-text>
      </div>
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="addSocialxp" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>
        Submit
      </b-button>
    </template>
  </b-modal>
</template>
